import { defineStore } from "pinia";
import { computed } from "vue";
import { RemovableRef, useSessionStorage } from "@vueuse/core";
import { AdminInfoResponse } from "src/generated/adminapi";
import * as Sentry from "@sentry/vue";
import cookie from "js-cookie";

export const useAuthStore = defineStore("auth", () => {
  const user: RemovableRef<AdminInfoResponse> = useSessionStorage(
    `stc_${process.env.VUE_APP_ENV}/loginUser`,
    {} as AdminInfoResponse
  );

  const getUser = computed(() => {
    return { ...user.value };
  });

  function setUser(userData: AdminInfoResponse) {
    user.value = userData;

    const scope = Sentry.getCurrentScope();
    const jwt = cookie.get("_at") ?? "";
    scope.setExtra("jwt", { jwt: jwt });
    scope.setUser({
      id: userData.UUID,
      email: userData.email,
    });
  }

  function resetUser() {
    user.value = {} as AdminInfoResponse;
  }

  return {
    user,
    getUser,
    setUser,
    resetUser,
  };
});
