import { boot } from "quasar/wrappers";
import * as Sentry from "@sentry/vue";

export default boot(({ app, router }) => {
  if (process.env.VUE_APP_ENV === "local") return;
  Sentry.init({
    app,
    dsn: "https://c1120cebce07dd96732f31811fa0fc10@o4507586466283520.ingest.us.sentry.io/4507586467921920",
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration(),
    ],

    normalizeDepth: 4,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for tracing.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
    tracePropagationTargets: [
      "localhost",
      /https:\/\/.*\.haii\.io/,
      /https:\/\/.*\.co.kr/,
    ],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    environment: process.env.VUE_APP_ENV,
  });
});
