import {boot} from "quasar/wrappers";
import {createI18n} from "vue-i18n";

import messages from "src/i18n";
import {useSettingStore} from "stores/useSettingStore";

export type MessageLanguages = keyof typeof messages;
// Type-define 'en-US' as the master schema for the resource
export type MessageSchema = typeof messages["ko"];

// See https://vue-i18n.intlify.dev/guide/advanced/typescript.html#global-resource-schema-type-definition
/* eslint-disable @typescript-eslint/no-empty-interface */
declare module "vue-i18n" {
  // define the locale messages schema
  export interface DefineLocaleMessage extends MessageSchema {
  }

  // define the datetime format schema
  export interface DefineDateTimeFormat {
  }

  // define the number format schema
  export interface DefineNumberFormat {
  }
}
/* eslint-enable @typescript-eslint/no-empty-interface */

const settingStore = useSettingStore();

export default boot(({app}) => {
  if (!settingStore.getSetting.locale) {
    settingStore.setSetting({locale: navigator.language === "ko-KR" ? "ko" : "en"})
  }
  const i18n = createI18n<{ message: MessageSchema }, MessageLanguages>({
    locale: settingStore.getSetting.locale,
    fallbackLocale: "en",
    legacy: false,
    messages,
  });

  // Set i18n instance on app
  app.use(i18n);
});
