import { boot } from "quasar/wrappers";
import mitt from "mitt";
import { NavigationGuardNext, RouteLocationNormalized } from "vue-router";

export default boot(({ app, router }) => {
  const emit = mitt();
  router.beforeEach(
    (
      to: RouteLocationNormalized,
      from: RouteLocationNormalized,
      next: NavigationGuardNext
    ) => {
      emit.all.clear();
      next();
    }
  );

  app.use({
    install: (Vue) => {
      Vue.provide("mitt", emit);
    },
  });
});
