const $NotificationRelated = {
  completeEdit: "수정이 완료되었습니다.",
  completeSave: "저장이 완료되었습니다.",
  canSetUpThreeWeekChallenge: "도전 훈련 3개를 설정 해주세요.",
  errorOccurredTrayAgain: "에러가 발생했습니다. 다시 시도해주세요",
  errorOccurredAndLogout: "에러가 발생했습니다. 종료 후 다시 접속해주세요",
  chooseAllEvaluationElements: "모든 평가 요소를 선택해주세요",
  completeRegister: "등록 완료",
  duplicatePatientCode: "중복된 이용권 번호입니다.",
  notSaved: "저장되지 않았습니다.",
  notExistUser: "유저 정보가 존재하지 않습니다.",
  enterAllValue: "모든 값을 입력해주세요.",
  canNotLoadInfo: "정보를 불러올 수 없습니다.",
};

const $Common = {
  all: "전체",
  cancel: "취소",
  delete: "삭제",
  modification: "수정",
  cancelModification: "수정 취소",
  confirmDelete: "삭제하면 복구할 수 없습니다. 계속하시겠습니까?",
  message: "메세지",
  greeting: "안녕하세요!",
  goodbye: "안녕히 가세요!",
  none: "없음",
  ok: "확인",
  or: "또는",
  subtitle: "",
  title: "",
  unknown: "모름",
  userManagement: "유저 관리",
  serviceName: "StroKcare",
  language: "언어",
  dashboard: "대시보드",
  retention: "리텐션",
  content: "",
  save: "저장하기",
  noData: "데이터가 없습니다.",
  points: "점",
  session: "회차",
  times: "회",

  // 날짜 관련
  weekdaysLong: "일요일 월요일 화요일 수요일 목요일 금요일 토요일",
  weekdaysShort: "일 월 화 수 목 금 토",
  today: "오늘",
  yesterday: "어제",
  weekly: "주간",
  daily: "일간",
  monthly: "월간",
  year: "년(4자)",
  month: "월",
  day: "일",
  days: "날짜",
  seconds: "초",
  before: "이전",
  monday: "월",
  tuesday: "화",
  wednesday: "수",
  thursday: "목",
  friday: "금",
  saturday: "토",
  sunday: "일",

  required: "필수 입력 항목입니다.",
  notValidBlanked: "공백은 입력할 수 없습니다.",
  notValidEmail: "이메일 형식이 올바르지 않습니다.",
  notValidPhone: "휴대전화 형식이 올바르지 않습니다.",
  lessThanNumCharacter: "{msg}자 이하로 입력해주세요.",
  moreThanNumCharacter: "{msg}자 이상으로 입력해주세요.",
  moreThanNum: "{msg} 이상으로 입력해주세요.",
  lessThanNum: "{msg} 이하로 입력해주세요.",
  notValidMaxAgeRule: "{msg}년도 이상으로 입력해주세요",

  //notify 메시지
  ...$NotificationRelated,

  institutionName: "기관명",
  peopleNum: "{msg}명",
};

const $PatientRelated = {
  // 환자 관련
  displayName: "이름",
  medicalNum: "이용권 번호",
  age: "나이",
  birthDT: "생년월일",
  birthYear: "년(4자)",
  gender: "성별",
  state: "상태",
  lastEvaluationDt: "마지막 평가",
  male: "남",
  female: "여",
  phone: "휴대전화",
  address: "주소",
  firstOnsetDT: "최초 질병 발생일",
  recentVisitDt: "최근 방문일",
  diseaseType: "질병 타입 ",
  disabilityType: "장애 타입",
  etcDisabilityType: "기타 장애 타입",
  authenticationNumber: "인증코드",
  email: "이메일",

  evaluation: "평가",

  // 평가 서브시스템
  mpt: "MPT",
  ddk: "DDK",
  respiration: "호흡",
  phonation_pitch: "발성 (음도)",
  phonation_loud: "발성 (음성 크기)",
  phonation_qual: "발성 (음질)",
  prosody: "운율",
  resonance: "공명",
  articulation: "조음",
  etc: "기타",

  vocalization: "발성",

  // 평가 피쳐
  forced_inspiration_expiration: "노력형 호흡",
  audible_inspiration: "가청흡기",
  inhalatory_stridor: "흡기 천명음",
  grunt_at_end_of_expiration: "호기말 킁킁 소리",
  pitch_level: "음도 수준",
  pitch_break: "음도 일탈",
  monopitch: "단음도",
  voice_tremor: "음성 떨림",
  myoclonus: "후두 간대성 근경련",
  diplophonia: "이중음도",
  monoloudness: "단음량",
  loudness_decay: "음성 강도의 저하",
  alternating_loudness: "교차 음량",
  excessive_loudness_variation: "과다 음량 변이",
  harsh_voice: "거친 음성",
  hoarse_wet_voice: "목쉰 음성",
  breathy_voice: "기식음",
  strained_strangled_voice: "쥐어짜는 음성",
  voice_stoppages: "음성 끊김",
  flutter: "빠른 음성 떨림",
  rate: "말속도",
  short_phrase: "짧은 구",
  increase_of_rate_in_segments: "분절 내 속도 증가",
  increase_of_rate_overall: "전반적 속도 증가",
  reduced_stress: "강세 저하",
  variable_rate: "속도 변이",
  prolonged_interval: "늘어진 간격",
  inappropriate_break: "부적절한 쉼",
  short_rushes_of_speech: "짧은 말 뭉침",
  excess_and_equal_stress: "과균등 강세",
  hypernasality: "과다비성",
  hyponasality: "과소비성",
  nasal_emission: "비강누출",
  weak_pressure_consonants: "약한 파열음",
  imprecise_consonants: "부정확한 자음",
  prolonged_phonemes: "음소 연장",
  repeated_phonemes: "음소 반복",
  irregular_articulatory_breakdown: "불규칙적 조음 붕괴",
  distorted_vowels: "모음 왜곡",
  clarity: "말 명료도",

  // db 매핑
  phonation: "발성",
};

const $TrainingLevelRelated = {
  Hz: "Hz",
  sec: "초",
  dB: "dB",
  percentile: "%",
  segment: "어절",
  bpm: "bpm",
  wps: "단어/초",
  repetition: "회 반복",
  repetitions: "회 반복",
  cnt: "개",
  decibel: "데시벨",
  pitch: "피치",

  setRepeatNum: "반복 횟수 설정",
  setTrainingLevel: "훈련 난이도 설정",
  checkTrainingLevel: "훈련 난이도 확인",
  setWeekChallenge: "이번 주 도전 훈련으로 설정",
  videos: "영상 시청",
  sound_len: "소리 길이",
  sound_vol: "소리 크기",
  sound_pitch: "소리 높낮이 (진폭)",
  raise_vol: "소리 올림",
  lower_vol: "소리 내림",
  sound_keep: "소리 높낮이 (파장)",
  quivering: "떨림 소리",
  unshakable: "떨림 없는 소리",
  accent: "강세",
  times: "횟수",
  beat_keep: "박자 맞추기",
  breathe_in: "들숨 시간",
  breathe_keep: "호흡 유지 시간",
  breathe_out: "날숨 시간",
  sentence_len: "문장 길이",
  read_speed: "읽기 속도",
  beat_speed: "박자 속도",
  success_rate: "성공률",
  sound_vol_cal_pl: "소리 크기",
  sound_vol_cal_mi: "소리 크기",
  sound_pitch_cal_mul_div: "소리 높낮이",
  sound_pitch_down_cal_div: "소리 높낮이",
  sound_pitch_up_cal_mul: "소리 높낮이",
  sound_pitch_amp_cal_pl_mi: "소리 높낮이(진폭)",
  _sound_pitch_wav_sec: "소리 높낮이 파장",
  sound_pitch_success_rate: "소리 높낮이 성공률",
  total_time: "총 시간",
  phrase: "어절",
  stretch_time: "늘려읽는 길이",

  // 훈련명
  warm_up: "자세 교정운동",
  oral_motor_exercise: "비구어 구강운동",
  sustained_phonation: "길게 소리내기",
  gliding_up: "점점 높게 소리내기",
  gliding_down: "점점 낮게 소리내기",
  louder: "점점 크게 소리내기",
  pitch_up_down: "높낮이 조절하기",
  effortful_closure: "성대 운동",
  yawn_sigh: "하품-한숨 운동",
  minimal_pair: "비슷한 단어 읽기",
  vocal_tremor_cycle: "성대 떨림 주기",
  interpreting_a_sentence: "문장 끊어 읽기",
  reading_stress: "문장 강조해서 읽기",
  say_with_a_melody: "멜로디 넣어 말하기",
  deep_breath: "호흡 하기",
  tapping: "박자 맞추기",
  reading_stretch: "문장 길게 늘려 읽기",
  reading_loud: "문장 크게 읽기",

  //비슷한 단어읽기
  machal_payeol: "마찰음의 - 파열음화",
  machal_pachal: "마찰음의 - 파찰음화",
  pachal_payeol: "파찰음의 - 파열음화",
  bieum_hwa: "비음화",
  gyeokeum_hwa: "격음화",
  gyeongeum_hwa: "경음화",
  cho_yangsoon: "초성생략 (양순음)",
  cho_yeongugae: "초성생략 (연구개음)",
  jong_yangsoon: "종성생략 (양순음)",
  jong_yeongugae: "종성생략 (연구개음)",
  jong_chigyeong: "종성생략 (치경음)",
  yooeum_err: "유음 오류",
  yeongugae_front: "연구개음 전방화",
  yangsoon_vs_chigyeong: "양순음 vs 치경음",
  yangsoon_vs_yeongugae: "양순음 vs 연구개음",
  chigyeong_vs_yeongugae: "치경음 vs 연구개음",
  payeol_vs_pachal: "파열음 vs 파찰음",
  payeol_vs_machal: "파열음 vs 마찰음",
};

const $RoleRelated = {
  255: "개발팀",
  245: "비 개발팀",
  190: "병원 대표 관리자",
  150: "언어 재활사",
};

export const ko = {
  AppBar: {
    ...$Common,
    ...$PatientRelated,
  },
  DrawerSetting: {
    ...$Common,
    ...$PatientRelated,
  },
  LanguageSet: {
    ...$Common,
    ...$PatientRelated,
  },
  DarkModeSet: {
    ...$Common,
    ...$PatientRelated,
  },
  DrawerMenuList: {
    ...$Common,
    ...$PatientRelated,
    patientManagement: "환자 관리",
    notice: "공지사항",
    profile: "프로필",
    logout: "로그아웃",
    userList: "유저 리스트",
    userAdd: "유저 추가",
  },
  Dashboard: {
    ...$Common,
    ...$PatientRelated,
  },
  DashboardHeader: {
    ...$Common,
    ...$PatientRelated,
    title: "대시보드",
  },
  DashboardTrainedStatus: {
    ...$Common,
    ...$PatientRelated,
    trainedStatus: "훈련 현황",
    untrained: "훈련하지 않은 사람",
    trained: "보통 훈련한 사람",
    hardTrained: "열심히 훈련한 사람",
    untrainedPercent: "진행률 0~10%",
    trainedPercent: "진행률 11~70%",
    hardTrainedPercent: "진행률 71~100%",
  },
  DashboardTrainedStatusGraph: {
    ...$Common,
    ...$PatientRelated,
    trainStatus: "훈련 추이",
  },
  PatientsHeader: {
    ...$Common,
    ...$PatientRelated,
    title: "환자 관리",
  },
  PatientsTableHeader: {
    ...$Common,
    ...$PatientRelated,
    title: "환자 정보",
    addPatient: "환자 추가하기",
    downExcel: "엑셀로 다운받기",
    totalPeople: "총 {msg}명",
    init: "초기화하기",
    selectPatient: "환자 선택하기",
  },
  PatientsTable: {
    ...$Common,
    ...$PatientRelated,
    title: "환자 정보",
    organizationInfo: "기관",
    avgTrain: "훈련률",
    avgTrainField: "{msg}일/ 주",
  },
  BodyCellState: {
    ...$Common,
    ...$PatientRelated,
    finishedTraining: "훈련 완료",
    inTraining: "훈련 중",
    noTraining: "훈련 안함",
  },
  BodyCellActionMenu: {
    ...$Common,
    ...$PatientRelated,
    sendAlarm: "알림 보내기",
    delete: "삭제하기",
  },
  SortIconTooltip: {
    ...$Common,
    ...$PatientRelated,
    reverseSort: "역순 정렬",
    stateTip: "훈련 안함•훈련 완료 순",
    lastEvaluationDtTip: "최종 평가 날짜 순",
  },
  DeleteDialog: {
    ...$Common,
    ...$PatientRelated,
    title: "{msg} 님의 정보를 정말 삭제하겠습니까?",
    content1:
      "삭제한 후에는 해당 환자의 모든 정보가 삭제되고 복구 할 수 없습니다.",
    content2: "",
    cancel: "취소",
    ok: "삭제",
  },
  Patient: {
    ...$Common,
    ...$PatientRelated,
    editItem: "환자 정보",
    ok: "정보",
  },
  PatientHeader: {
    ...$Common,
    ...$PatientRelated,
    patientInfo: "환자 정보",
    voiceEvaluation: "평가",
    trainingManagement: "훈련 관리",
    trainingReport: "리포트",
  },
  PatientBasicInfo: {
    ...$Common,
    ...$PatientRelated,
    title: "기본 정보",
    diagnosisInfo: "진단 정보",
    addPatient: "환자 추가",
    editItem: "환자 정보",
    xai: "XAI 기능",
    ok: "정보",
    edit: "수정",
    adminName: "담당자",
  },

  NotSaveDialog: {
    ...$Common,
    ...$PatientRelated,
    title: "{msg}를 저장해주세요!",
    content: "다른 페이지로 이동 시 입력하신 정보가 저장되지 않습니다.",
    cancel: "저장하지 않고 이동",
    ok: "{msg} 계속 입력하기",
  },
  PatientListTableSendAlarmDialog: {
    ...$Common,
    ...$PatientRelated,
    title: "알림 보내기",
    content: "알림 문구를 선택하면 환자에게 알림이 전송됩니다.",
    ok: "확인",
    hardTrained: "꾸준히 잘 훈련하고 계세요! 앞으로도 지금처럼 해주세요~",
    lowTrained: "요즘 훈련이 저조해요∙∙∙, 잊으신 것은 아니죠?",
    needEvaluation: "최근 평가를 하지않으셨어요. 평가를 진행해주세요!",
  },
  DatePicker: {
    ...$Common,
    ...$PatientRelated,
    notChooseSameDate: "서로 다른 날짜를 선택해주세요.",
    chooseDates: "날짜를 선택해주세요",
    showToday: "오늘 보기",
  },
  TableFilter: {
    ...$Common,
    ...$PatientRelated,
    trainStatus: "훈련 상태",
    finishedTraining: "훈련 완료",
    inTraining: "훈련 중",
    noTraining: "훈련 안함",
    institutionName: "기관",
    done: "완료",
    notDone: "미완료",
  },

  PatientBasicInfoAddressInput: {
    ...$Common,
    ...$PatientRelated,
    search: "검색",
    detailedAddress: "상세 주소",
  },
  PatientDiagnosticInfo: {
    ...$Common,
    ...$PatientRelated,
    title: "진단 정보",
    symptomAndContentNotes: "증상 및 내용 메모",
    severityLevel: "심각도",
  },
  PatientInfo: {
    ...$Common,
    ...$PatientRelated,
    add: "추가하기",
  },
  CompleteAddPatientDialog: {
    ...$Common,
    ...$PatientRelated,
    title: "[{msg}]님이 추가되었습니다.",
    content1: "환자에게 아래 정보를 안내해 주세요.",
    content2: "환자 정보에서도 확인하실 수 있습니다.",
  },
  Evaluation: {
    ...$Common,
    ...$PatientRelated,
    editItem: "평가",
  },
  VoiceEvaluation: {
    ...$Common,
    ...$PatientRelated,
  },
  VoiceEvaluationHeader: {
    ...$Common,
    ...$PatientRelated,
    title: "평가입력",
  },
  ListenVoices: {
    ...$Common,
    ...$PatientRelated,
    title: "들어보기",
    readWord: "단어 읽기",
    readParagraph: "문장 읽기",
    a: "아",
    i: "이",
    u: "우",
    p: "퍼",
    t: "터",
    k: "커",
    ptk: "퍼터커",
    utap: "UTAP-2",
    readingFallParagraphs: "가을문단읽기",
  },
  EvaluationElements: {
    ...$Common,

    ...$PatientRelated,
    title: "평가요소",
    editItem: "평가",
    edit: "수정",
    readWord: "단어 읽기",
    readParagraph: "문장 읽기",
    rating: "평가",
    forced_inspiration_expiration_tip:
      "말하는 도중, 갑작스럽게 숨을 들이쉬거나 내쉬는 현상이 관찰되는가",
    audible_inspiration_tip: "숨을 들이쉴 때 소리가 나는가",
    inhalatory_stridor_tip:
      "숨을 들이쉴 때 성대의 접촉과 진동으로 인하여 거친 소리가 나는가?",
    grunt_at_end_of_expiration_tip: "숨을 내쉬는 끝 무렵에 킁킁소리가 나는가?",
    pitch_level_tip: "음성의 높이가 나이/성별에 적당한가?",
    pitch_break_tip: "음성 높이가 갑작스럽게 조절되지 않은 변화를 보이는가?",
    monopitch_tip: "음성 높이가 변화 없이 너무 단조로운가?",
    voice_tremor_tip: "음성이 떨리는가?",
    myoclonus_tip:
      "규칙적인 beats가 관찰되며, 모음연장시 음성이 끊기기도 하는가?",
    diplophonia_tip: "두 가지 음도의 음성이 들리는가?",
    monoloudness_tip: "음성 크기가 변화 없이 너무 단조로운가?",
    loudness_decay_tip: "음성 크기가 점차적으로 줄어드는가?",
    alternating_loudness_tip: "음성 크기가 컸다 작았다 하는가?",
    excessive_loudness_variation_tip:
      "음성 크기가 갑작스럽게 조절되지 않은 심한 변화를 보이는가?",
    harsh_voice_tip: "음성이 거친가?",
    hoarse_wet_voice_tip: "음성이 젖은 듯하고 쉰 듯한가?",
    breathy_voice_tip: "음성이 계속적으로 바람 새는 듯하고, 약하고, 가는가?",
    strained_strangled_voice_tip: "음성이 쥐어짜는 듯한가?",
    voice_stoppages_tip: "음성이 갑작스럽게 멈추는 현상이 관찬되는가?",
    flutter_tip: "빠르고 비교적 낮은 강도의 음성 떨림이 관찰되는가?",
    rate_tip: "말속도가 정상인가?",
    short_phrase_tip: "말이 짧게 끊기는가?",
    increase_of_rate_in_segments_tip: "말분절 내에서 말속도가 빨라지는가?",
    increase_of_rate_overall_tip: "전반적으로 말속도가 빨라지는가?",
    reduced_stress_tip: "말이 단조롭고 강세가 없는가?",
    variable_rate_tip: "말 속도가 변이적인가?",
    prolonged_interval_tip: "음절이나 단어 사이의 간격이 늘어지는가?",
    inappropriate_break_tip: "말 도중에 부적절하게 아무 말을 안 하는가?",
    short_rushes_of_speech_tip: "쫓기며 돌진하는 듯 서두르며 말하는가?",
    excess_and_equal_stress_tip:
      "음절이나 단어마다 과도하게 똑같은 강세로 말하는가?",
    hypernasality_tip: "비정상적으로 과다한 비성이 들리는가?",
    hyponasality_tip: "비정상적으로 적은 비성이 들리는가?",
    nasal_emission_tip: "말할 때에 코에서 콧김이 나오는가?",
    weak_pressure_consonants_tip: "자음이 약하게 조음되는가?",
    imprecise_consonants_tip: "자음이 부정확한가?",
    prolonged_phonemes_tip: "음소를 늘려서 조음하는가?",
    repeated_phonemes_tip: "음소를 반복하는가?",
    irregular_articulatory_breakdown_tip:
      "불규칙적으로 조음의 정확성이 무너지는가?",
    distorted_vowels_tip: "모음이 왜곡되는가?",
    none: "매우 불명료",
    severe: "매우 명료",
  },
  EvaluationResultHeader: {
    ...$Common,
    ...$PatientRelated,
    title: "음성 평가 및 설문 결과",
    round: "{msg}회차",
  },
  SubsystemDetailResult: {
    ...$Common,
    ...$PatientRelated,
    subsystem: "서브 시스템",
  },
  SubsystemResultChart: {
    ...$Common,
    ...$PatientRelated,
    ...$TrainingLevelRelated,
    beginning: "초기",
    recently: "최근",
  },
  SubsystemFeatureDetailResult: {
    ...$Common,
    ...$PatientRelated,
    scoreDetailResults: "점수 세부 결과",
    detailedItem: "세부 항목",
    evaluationResult: "점수",
    scoreChange: "점수 변화",
    back: "돌아가기",
  },
  DetailResultTable: {
    ...$Common,

    ...$PatientRelated,
  },
  MPT: {
    ...$Common,
    ...$PatientRelated,
    normalStandard: "정상규준",
  },
  DDK: {
    ...$Common,
    ...$PatientRelated,
    p: "퍼",
    t: "터",
    k: "커",
    ptk: "퍼터커",
    normalStandard: "정상규준",
  },
  PHQ9: {
    ...$Common,
    ...$PatientRelated,
    title: "우울 척도",
    normal: "정상 (5)",
    mild: "가벼운 우울 (10)",
    moderate: "중한 우울 (15)",
    severe: "심한 우울 (27)",
  },
  QualityOfLife: {
    ...$Common,
    ...$PatientRelated,
    title: "삶의 질 척도 ",
    wordProperty: "말 특성",
    situationalDifficulties: "상황적 어려움",
    compensationStrategy: "보상 전략",
    perceptualReaction: "타인 지각 반응",
  },
  WordArticulation: {
    ...$Common,
    ...$PatientRelated,
    title: "말 명료도",
    none: "매우 불명료_ (0)",
    mild: "불명료_ (25)",
    moderate: "중간_ (50)",
    moderateSevere: "명료_ (75)",
    severe: "매우 명료_ (100)",
  },
  DIPBefore: {
    ...$Common,
    ...$PatientRelated,
    title: "DIP",
  },
  DIPTable: {
    ...$Common,
    ...$PatientRelated,
    title: "DIP scores by section",
  },

  NotEvaluate: {
    ...$Common,
    ...$PatientRelated,
    subsystem: "서브 시스템",
    MPT: "MPT",
    DDK: "DDK",
    PHQ9: "우울",
    DIP: "DIP",
    qualityOfLife: "삶의 질",
    wordArticulation: "말 명료도",
    careGiverWordArticulation: "케어기버 말 명료도",
    caregiverTable: "케어기버",
    caregiverWordArticulation: "",
    totalRetentionUsers: "총 환자수",
    finishedRetentionUsers: "완료 환자수",
    appUsage: "앱 사용률",
    trainingPCT: "훈련률",
    usageByInstitution: "기관 별 사용률",
  },
  TrainingManagement: {
    ...$Common,
    ...$PatientRelated,
    ...$TrainingLevelRelated,
    all: "모든 훈련",
    editItem: "설정한 훈련",
    ok: "훈련",
  },
  TrainingManagementHeader: {
    ...$Common,
    ...$PatientRelated,
    title: "훈련 추가하기",
    trainingArea: "훈련 영역",
    all: "모든 훈련",
  },
  TrainingItem: {
    ...$Common,
    ...$PatientRelated,
    ...$TrainingLevelRelated,
    warm_up_trainingManagementDescription: "자세 교정 영상",
    oral_motor_exercise_trainingManagementDescription: "비구어 구강 운동 영상",
    sustained_phonation_trainingManagementDescription: "최대한 길게 소리내기",
    gliding_up_trainingManagementDescription: "음을 점점 높게 소리내기",
    gliding_down_trainingManagementDescription: "음을 점점 낮게 소리내기",
    louder_trainingManagementDescription: "소리를 점점 크게내기",
    pitch_up_down_trainingManagementDescription: "소리의 높낮이 조절하기",
    effortful_closure_trainingManagementDescription: "성대 근력 운동 영상",
    yawn_sigh_trainingManagementDescription: "성대 이완 운동 영상",
    minimal_pair_trainingManagementDescription: "최소 대립쌍 단어읽기",
    reading_stress_trainingManagementDescription: "강세를 주어 문장읽기",
    deep_breath_trainingManagementDescription: "코와 입으로 심호흡하기",
    tapping_trainingManagementDescription: "박자에 맞춰 두드리며 문장읽기",
    reading_stretch_trainingManagementDescription: "천천히 늘려서 문장읽기",
    reading_loud_trainingManagementDescription: "최대한 크게 문장읽기",
  },
  TrainingManagementWeekTrainingList: {
    ...$Common,
    ...$PatientRelated,
    ...$TrainingLevelRelated,
    title: "이번 주 훈련 리스트",
    noTrainingList: "최소 1개의 훈련을 선택해주세요.",
  },
  DetailTrainingSetting: {
    ...$Common,
    ...$PatientRelated,
    ...$TrainingLevelRelated,
    warm_up_trainingDescription:
      "훈련 시작 전 영상을 보고 자세 교정을 진행하는 훈련입니다.",
    oral_motor_exercise_trainingDescription:
      "영상을 보고 비구어 구강운동을 진행하는 훈련입니다. ",
    sustained_phonation_trainingDescription:
      "음을 최대한 길게 소리 내는 훈련입니다.",
    gliding_up_trainingDescription: "음도를 높이는 훈련입니다. ",
    gliding_down_trainingDescription: "음도를 낮추는 훈련입니다. ",
    louder_trainingDescription: "음을 점점 크게 소리내는 훈련입니다.",
    pitch_up_down_trainingDescription:
      "음도를 반복적으로 높였다 낯추는 훈련입니다.",
    effortful_closure_trainingDescription:
      "영상을 보고 성대 근력(노력성 폐쇄) 운동을 진행하는 훈련입니다.  ",
    yawn_sigh_trainingDescription:
      "영상을 보고 성대 이완(하품-한숨) 운동을 진행하는 훈련입니다.  ",
    minimal_pair_trainingDescription:
      "두 개의 단어(최소 대립쌍)를 번갈아 읽는 훈련입니다.",
    reading_stress_trainingDescription:
      "문장의 특정 부분에 강세를 주어 읽는 훈련입니다.",
    deep_breath_trainingDescription: "코와 입으로 각각 심호흡하는 훈련입니다.",
    tapping_trainingDescription:
      "박자에 맞춰 손가락을 두드리면서 단어/문장을 읽는 훈련입니다. ",
    reading_stretch_trainingDescription:
      "문장을 길고 천천히 늘려서 읽는 훈련입니다.",
    reading_loud_trainingDescription: "문장을 최대한 크게 읽는 훈련입니다.",
  },
  SetWeekTrainingDialog: {
    ...$Common,
    ...$PatientRelated,
    ...$TrainingLevelRelated,
    title: "이번 주 훈련 설정",
    description: "설정한 이번 주 훈련 리스트와 도전 훈련 3개를 확인해 주세요.",
  },
  TrainingStatus: {
    ...$Common,
    ...$PatientRelated,
    title: "달력",
  },
  TrainingStatusCalendar: {
    ...$Common,
    ...$PatientRelated,
    title: "달력",
    resetDate: "기간 재설정",
    sendNotification: "알림 보내기",
    trialPeriod: "임상 기간",
    trainingRate: "훈련률",
    notification: "알림",
  },
  TrainingStatusChart: {
    ...$Common,
    ...$PatientRelated,
    title: "훈련률 추이",
  },
  TrainingReportWeekTrainingList: {
    ...$Common,

    ...$PatientRelated,
    ...$TrainingLevelRelated,
    displayName: "이번 주 훈련 리스트",
    challengeTrainStatus: "도전 훈련 여부",
    completionRate: "완료율",
    listening: "들어보기",
    download: "다운로드",
    noData: "이번 주 훈련 리스트가 없습니다.",
  },
  TrainingResult: {
    ...$Common,
    ...$PatientRelated,
    ...$TrainingLevelRelated,
  },
  UserProfile: {
    ...$Common,
    ...$PatientRelated,
    title: "프로필",
    basicInfo: "기본 정보",
    accountInfo: "계정 정보",
    dialogTitle: "정말 계정을 삭제할까요?",
    content: "계정 삭제 시, 모든 정보가 복구되지 않습니다.",
    ok: "계정 삭제",
  },
  UserProfileHeader: {
    ...$Common,
    ...$PatientRelated,
    title: "프로필",
  },
  UserLogout: {
    ...$Common,
    ...$PatientRelated,
    logout: "로그아웃",
    content: "로그아웃 하시겠습니까?",
  },
  UserLogoutHeader: {
    ...$Common,
    ...$PatientRelated,
    title: "로그아웃",
  },
  UserList: {
    ...$Common,
    ...$PatientRelated,
  },
  UserListHeader: {
    ...$Common,
    ...$PatientRelated,
    title: "유저 리스트",
  },
  UsersTable: {
    ...$Common,
    ...$PatientRelated,
    role: "권한",
    totalPeople: "총 {msg}명",
  },
  UsersTableHeader: {
    ...$Common,
    ...$PatientRelated,
    title: "유저 정보",
    addUser: "유저 추가",
  },
  UserAdd: {
    ...$Common,
    ...$PatientRelated,
  },
  UserAddHeader: {
    ...$Common,
    ...$PatientRelated,
    title: "유저 추가",
    excelDownload: "업로드 양식 다운로드",
    excelUpload: "엑셀 업로드",
    role: "권한",
  },
  UserInputTable: {
    ...$Common,
    ...$PatientRelated,
    role: "권한",
    totalPeople: "총 {msg}명",

    invite: "초대하기",
  },
  TrainingResultChart: {
    ...$Common,
    ...$PatientRelated,
    ...$TrainingLevelRelated,
  },
  DetailTrainingSettingArea: {
    ...$Common,
    ...$PatientRelated,
    ...$TrainingLevelRelated,
  },
  SetTrainingLevel: {
    ...$PatientRelated,
    ...$TrainingLevelRelated,
    highest: "최상",
    upper: "상",
    middle: "중",
    low: "하",
    times: "늘려읽는 개수",
  },
  DefaultSettingArea: {
    ...$Common,
    ...$TrainingLevelRelated,
  },
  MinimalPairSettingArea: {
    ...$Common,
    ...$PatientRelated,
    ...$TrainingLevelRelated,
    setTrainingLevel: "훈련 맞춤화 설정",
    bilabial_sound: "bilabial sound",
    labiodental: "labiodental",
    alveolar: "alveolar",
    velar: "velar",
    nasalization: "nasalization",
    alveolar_for_palatal_sounds: "alveolar for palatal sounds",
    stopping: "stopping",
    cluster_reduction_l_blen: "Cluster reduction: /l/ in l-blends ",
    cluster_reduction_s_blen: "Cluster reduction: /s/ in s-blends",
  },

  UserTable: {
    ...$Common,
    ...$PatientRelated,
    ...$RoleRelated,
  },
  BodyCellRole: {
    ...$Common,
    ...$PatientRelated,
    ...$RoleRelated,
  },
  CareGiverHeader: {
    ...$Common,
    ...$PatientRelated,
    ...$RoleRelated,
    title: "케어기버 평가 결과",
  },
  CaregiverResultTable: {
    ...$Common,
    ...$PatientRelated,
    ...$RoleRelated,
    date: "날짜",
    week: "주차",
    topic: "주제",
    listen: "들어보기",
    selectionResults: "선택 결과",
  },
  CareGiverWordArticulation: {
    ...$Common,
    ...$PatientRelated,
    ...$RoleRelated,
    title: "케어기버 말 명료도",
    none: "매우 불명료",
    mild: "불명료",
    moderate: "중간",
    moderateSevere: "명료",
    severe: "매우 명료",
  },

  UserDeleteDialog: {
    ...$Common,
    ...$PatientRelated,
    deleteAccount: "계정 삭제",
    title: "정말 계정을 삭제할까요?",
    content1: "계정 삭제 시, 모든 정보가 복구되지 않습니다.",
    content2: "",
  },
  LastNotification: {
    title: "지난 알림",
    totalNum: "총 {msg}건",
  },
  SendAlarm: {
    ...$Common,
    title: "알림 보내기",
    subtitle: "알림 문구를 선택하면 환자에게 알림과 문자로 전송됩니다.",
    titleLabel: "제목",
    contentsLabel: "내용",
    length: "자",
    send: "보내기",
    basicMessage1Title: "또 한주가 밝았네요!",
    basicMessage1Content: "오늘의 훈련을 진행 해주세요~!",
    basicMessage2Title: "잊으신 것은 아니죠?",
    basicMessage2Content: "많이 바쁘셨나 봐요.... 오늘은 와주실 거죠?",
  },
  ConfirmSendDialog: {
    ...$Common,
    title: "알림을 정말 보내겠습니까?",
    subtitle: "다음 내용을 환자의 알림과 문자로 전송합니다.",
    cancel: "취소하기",
    ok: "보내기",
    NoContents: "기기 등록을 해주세요.",
    completed: "전송 완료",
  },
  EvaluationDialog: {
    ...$Common,
    title: "평가 띄우기",
    floatPopup: "평가 버튼 띄우기",
    completed: "전송 완료",
    guideMessage: "{msg}님의 음성 평가를 위해 앱에 평가 버튼이 뜨도록 합니다.",
  },
  RetentionInformation: {
    ...$Common,
    appUsage: "앱 사용률",
    trainingRate: "훈련률",
    usedDaysTotal: "총 사용일수",
    usedDaysReal: "사용일수",
    appUsageGuide: "(훈련한 일수 기준)",
    trainingRateGuide: "(훈련 진행률 기준)",
    usedDaysTotalGuide: "(주말 포함)",
    usedDaysRealGuide: "(주말 제외)",
    days: "{msg}일",
  },
  TrainingReport: {
    ...$Common,
  },
  RetentionHeader: {
    ...$Common,
    title: "리텐션",
  },
  PatientTrainingInformation: {
    ...$Common,
    totalRetentionUsers: "총 환자수",
    finishedRetentionUsers: "완료 환자수",
    appUsage: "앱 사용률",
    trainingPCT: "훈련률",
    appUsageGuide: "(훈련한 일수 기준)",
    trainingPCTGuide: "(훈련 진행률 기준)",
  },
  UsageByInstitution: {
    ...$Common,
    title: "기관 별 사용률",
  },
  PatientsSelectDialog: {
    ...$Common,
    ...$PatientRelated,
    title: "환자 선택하기",
    organizationInfo: "기관",
    add: "추가하기",
    guideMessage: "{msg}명 선택됨",
  },
  RetentionPatientsTable: {
    ...$Common,
    ...$PatientRelated,
    title: "환자 정보",
    organizationInfo: "기관",
    avgTrain: "앱 사용률",
    avgTrainField: "{msg}일/ 주",
    done: "종료",
    planStartTS: "시작일",
  },
  DefaultEvaluationValues: {
    ...$Common,
    ...$TrainingLevelRelated,
    title: "{msg} 님의 기본(평가) 값",
    tooltipGuide: "최근 평가에서 도출된 환자의 기준 수치",
  },
};
