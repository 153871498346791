import { ko } from "src/i18n/ko";

const $NotificationRelated = {
  completeEdit: "The information has been modified.",
  completeSave: "The information has been saved.",
  canSetUpThreeWeekChallenge: "Please set up 3 challenge training.",
  errorOccurredTrayAgain: "An error has occurred. Please try again.",
  errorOccurredAndLogout:
    "An error has occurred. Please turn it off and connect again.",
  chooseAllEvaluationElements: "Please select all evaluation features.",
  completeRegister: "Registration completed.",
  duplicatePatientCode: "The voucher number already exists.",
  notSaved: "The information has not been saved.",
  notExistUser: "The user does not exist.",
  enterAllValue: "Please enter all values.",
  canNotLoadInfo: "Failed to load information.",
};

const $Common = {
  all: "All",
  cancel: "Cancel",
  modification: "Modification",
  cancelModification: "Cancel modification",
  confirmDelete: "Are you sure you want to delete this?",
  delete: "Delete",
  message: "Message",
  greeting: "Hi There!",
  goodbye: "Goodbye!",
  none: "None",
  ok: "OK",
  or: "or",
  subtitle: "",
  title: "",
  unknown: "Unknown",
  content: "",
  save: "Save",
  noData: "No data available.",

  userManagement: "User management",
  serviceName: "StroKcare",
  language: "language",
  dashboard: "Dashboard",
  retention: "retention",
  points: "points",
  session: "session",
  times: "times",

  // 날짜 관련
  weekdaysLong: "Sunday Monday Tuesday Wednesday Thursday Friday Saturday",
  weekdaysShort: "Sun Mon Tue Wed Thu Fri Sat",
  today: "Today",
  yesterday: "Yesterday",
  weekly: "Weekly",
  daily: "Daily",
  monthly: "Monthly",
  year: "Year",
  month: "Month",
  day: "Day",
  days: "Days",
  seconds: "sec",
  before: "baseline",
  monday: "monday",
  tuesday: "tuesday",
  wednesday: "wednesday",
  thursday: "thursday",
  friday: "friday",
  saturday: "saturday",
  sunday: "sunday",

  required: "This is required.",
  notValidBlanked: "Blank are not allowed",
  notValidEmail: "Please enter a valid email address.",
  notValidPhone: "Please enter a valid phone number.",
  lessThanNumCharacter: "Please enter a value less than {msg}.",
  moreThanNumCharacter: "Please enter a value more than {msg}.",
  moreThanNum: "Please enter a value more than {msg}.",
  lessThanNum: "Please enter a value less than {msg}.",
  notValidMaxAgeRule: "Please enter birth year later than {msg}",

  //notify 메시지
  ...$NotificationRelated,

  institutionName: "Institution",
  peopleNum: "{msg} patients",
};

const $PatientRelated = {
  // 환자 관련
  displayName: "Name",
  medicalNum: "Voucher number",
  age: "Age",
  birthDT: "Date of birth",
  birthYear: "Year (4 digits)",
  gender: "Gender",
  state: "Status",
  lastEvaluationDt: "Last evaluation",
  male: "Male",
  female: "Female",
  phone: "Mobile no.",
  address: "Address",
  firstOnsetDT: "Date of onset",
  recentVisitDt: "recent visit",
  diseaseType: "Type of disorder",
  disabilityType: "Disability type",
  etcDisabilityType: "Other disability type",
  authenticationNumber: "Verification code",
  email: "Email address",

  evaluation: "Evaluation",

  // 평가 서브시스템
  mpt: "mpt",
  ddk: "ddk",
  respiration: "Respiration",
  phonation_pitch: "Phonation (pitch)",
  phonation_loud: "Phonation (loudness)",
  phonation_qual: "Phonation (voice quality)",
  prosody: "Prosody",
  resonance: "Resonance",
  articulation: "Articulation",
  etc: "etc",

  vocalization: "Phonation",

  // 평가 피쳐
  forced_inspiration_expiration: "forced_inspiration-expiration",
  audible_inspiration: "audible_inspiration",
  inhalatory_stridor: "inhalatory_stridor",
  grunt_at_end_of_expiration: "grunt_at_end_of_expiration",
  pitch_level: "pitch_level",
  pitch_break: "pitch_break",
  monopitch: "monopitch",
  voice_tremor: "voice_tremor",
  myoclonus: "myoclonus",
  diplophonia: "diplophonia",
  monoloudness: "monoloudness",
  loudness_decay: "loudness_decay",
  alternating_loudness: "alternating_loudness",
  excessive_loudness_variation: "excessive_loudness_variation",
  harsh_voice: "harsh_voice",
  hoarse_wet_voice: "hoase / wet voice",
  breathy_voice: "breathy_voice",
  strained_strangled_voice: "strained-strangled",
  voice_stoppages: "voice_stoppages",
  flutter: "flutter",
  rate: "rate",
  short_phrase: "short_phrase",
  increase_of_rate_in_segments: "increase_of_rate_in_segments",
  increase_of_rate_overall: "increase_of_rate_overall",
  reduced_stress: "reduced_stress",
  variable_rate: "variable_rate",
  prolonged_interval: "prolonged_interval",
  inappropriate_break: "inappropriate_break",
  short_rushes_of_speech: "short_rushes_of_speech",
  excess_and_equal_stress: "excess_and_equal_stress",
  hypernasality: "hypernasality",
  hyponasality: "hyponasality",
  nasal_emission: "nasal_emission",
  weak_pressure_consonants: "weak_pressure_consonants",
  imprecise_consonants: "imprecise_consonants",
  prolonged_phonemes: "prolonged_phonemes",
  repeated_phonemes: "repeated_phonemes",
  irregular_articulatory_breakdown: "irregular_articulatory_breakdowns",
  distorted_vowels: "distorted_vowels",
  clarity: "Speech intelligibility",

  // db 매핑
  phonation: "Phonation",
};

const $TrainingLevelRelated = {
  segment: "segment",
  Hz: "Hz",
  repetition: "repetition",
  repetitions: "repetitions",
  dB: "dB",
  sec: "sec",
  percentile: "%",
  bpm: "bpm",
  wps: "word/seconds",
  cnt: "",
  decibel: "Decibel",
  pitch: "Pitch",

  setRepeatNum: "Set number of repetitions",
  setTrainingLevel: "Set training difficulty level",
  checkTrainingLevel: "Training difficulty standards",
  setWeekChallenge: "Set to this week's challenge",
  videos: "videos",
  sound_len: "Length",
  sound_vol: "Volume",
  sound_pitch: "Pitch (amplitude)",
  raise_vol: "High pitch",
  lower_vol: "Low pitch",
  sound_keep: "Pitch (wavelength)",
  quivering: "quivering",
  unshakable: "unshakable",
  accent: "accent",
  times: "times",
  beat_keep: "beat_keep",
  breathe_in: "Inhalation",
  breathe_keep: "Hold",
  breathe_out: "Exhalation",
  sentence_len: "Sentence length",
  read_speed: "Reading speed",
  beat_speed: "Tapping speed",
  success_rate: "Success rate",
  sound_vol_cal_pl: "Volume",
  sound_vol_cal_mi: "Volume",
  sound_pitch_cal_mul_div: "Pitch",
  sound_pitch_down_cal_div: "Pitch",
  sound_pitch_up_cal_mul: "Pitch",
  sound_pitch_amp_cal_pl_mi: "Pitch (amplitude)",
  _sound_pitch_wav_sec: "Pitch (wavelength)",
  sound_pitch_success_rate: "Success rate of pitch variation",
  total_time: "Total time",
  phrase: "segment",
  stretch_time: "Length of prolonged sound",

  warm_up: "Posture correction",
  oral_motor_exercise: "Oral motor exercises",
  sustained_phonation: "Sustained phonation",
  gliding_up: "Gliding up",
  gliding_down: "Gliding down",
  louder: "Volume up",
  pitch_up_down: "Pitch control",
  effortful_closure: "Effortful closure",
  yawn_sigh: "Yawn-sign exercises",
  minimal_pair: "Contrast drills",
  vocal_tremor_cycle: "vocal_tremor_cycle",
  interpreting_a_sentence: "interpreting_a_sentence",
  reading_stress: "Contrastive stress ",
  say_with_a_melody: "say_with_a_melody",
  deep_breath: "Breathing exercises",
  tapping: "Tapping",
  reading_stretch: "Slow reading",
  reading_loud: "Loud reading",

  // 비슷한 단어읽기
  machal_payeol: "Stopping of fricatives",
  machal_pachal: "Affrication of fricatives",
  pachal_payeol: "Stopping of affricatives",
  bieum_hwa: "Nasalization",
  gyeokeum_hwa: "Aspiration",
  gyeongeum_hwa: "Tensing",
  cho_yangsoon: "Initial Consonant Deletion (Bilabial sound)",
  cho_yeongugae: "Initial Consonant Deletion (Velar)",
  jong_yangsoon: "Final Consonant Deletion (Bilabial sound)",
  jong_yeongugae: "Final Consonant Deletion (Velar)",
  jong_chigyeong: "Final Consonant Deletion (Alveolar)",
  yooeum_err: "Liquid sounds error",
  yeongugae_front: "Velar fronting",
  yangsoon_vs_chigyeong: "양순음 vs 치경음",
  yangsoon_vs_yeongugae: "양순음 vs 연구개음",
  chigyeong_vs_yeongugae: "치경음 vs 연구개음",
  payeol_vs_pachal: "파열음 vs 파찰음",
  payeol_vs_machal: "파열음 vs 마찰음",
};

const $RoleRelated = {
  255: "개발팀",
  245: "비 개발팀",
  190: "병원 대표 관리자",
  150: "언어 재활사",
};

export const en: typeof ko = {
  AppBar: {
    ...$Common,
    ...$PatientRelated,
  },
  DrawerSetting: {
    ...$Common,
    ...$PatientRelated,
  },
  LanguageSet: {
    ...$Common,
    ...$PatientRelated,
  },
  DarkModeSet: {
    ...$Common,
    ...$PatientRelated,
  },
  DrawerMenuList: {
    ...$Common,
    ...$PatientRelated,
    patientManagement: "My patients",
    notice: "Announcements",
    profile: "My profile",
    logout: "Logout",
    userList: "User list",
    userAdd: "User add",
  },
  Dashboard: {
    ...$Common,
    ...$PatientRelated,
  },
  DashboardHeader: {
    ...$Common,
    ...$PatientRelated,
    title: "Dashboard",
  },
  DashboardTrainedStatus: {
    ...$Common,
    ...$PatientRelated,
    trainedStatus: "Overview",
    untrained: "Not started",
    trained: "In progress",
    hardTrained: "On track",
    untrainedPercent: "Progress 0~10%",
    trainedPercent: "Progress 11~70%",
    hardTrainedPercent: "Progress 71~100%",
  },
  DashboardTrainedStatusGraph: {
    ...$Common,
    ...$PatientRelated,
    trainStatus: "Training trend",
  },
  PatientsHeader: {
    ...$Common,
    ...$PatientRelated,
    title: "Patient management",
  },
  PatientsTableHeader: {
    ...$Common,
    ...$PatientRelated,
    title: "Patient information",
    addPatient: "Add a patient",
    downExcel: "Excel download",
    totalPeople: "Total of {msg} patients",
    init: "CLEAR ALL",
    selectPatient: "Select patient",
  },
  PatientsTable: {
    ...$Common,
    ...$PatientRelated,
    organizationInfo: "Institution",
    medicalNum: "Voucher no.",
    title: "Patient information",
    avgTrain: "Average training days",
    avgTrainField: "{msg} day/week",
  },
  BodyCellState: {
    ...$Common,
    ...$PatientRelated,
    finishedTraining: "On track",
    inTraining: "In progress",
    noTraining: "Not started",
  },
  BodyCellActionMenu: {
    ...$Common,
    ...$PatientRelated,
    sendAlarm: "Send Alarm",
  },
  SortIconTooltip: {
    ...$Common,
    ...$PatientRelated,
    reverseSort: "Reverse sorting",
    stateTip: "Sort by not started - on track",
    lastEvaluationDtTip: "Sort by latest evaluation date",
  },
  DeleteDialog: {
    ...$Common,
    ...$PatientRelated,
    title: "Are you sure you want to delete {msg}'s information?",
    content1: "All information for this patient will be permanently deleted.",
    content2: "This information cannot be restored after deletion.",
    cancel: "cancel",
    ok: "delete",
  },
  Patient: {
    ...$Common,
    ...$PatientRelated,
    editItem: "patient information!",
    ok: "information",
  },
  PatientHeader: {
    ...$Common,
    ...$PatientRelated,
    patientInfo: "Patient info",
    voiceEvaluation: "Evaluation",
    trainingManagement: "Management",
    trainingReport: "Report",
  },
  PatientBasicInfo: {
    ...$Common,
    ...$PatientRelated,
    title: "Basic information",
    diagnosisInfo: "Diagnosis Information",
    addPatient: "Add a patient",
    editItem: "patient information",
    ok: "information",
    edit: "Edit",
    xai: "XAI mode",
    medicalNum: "Voucher no.",
    adminName: "Assigned admin",
  },

  NotSaveDialog: {
    ...$Common,
    ...$PatientRelated,
    title: "Please save {msg}",
    content:
      "If you leave this page now, the information you have entered will not be saved.",
    cancel: "Leave without saving",
    ok: "Stay on this page",
  },
  PatientListTableSendAlarmDialog: {
    ...$Common,
    ...$PatientRelated,
    hardTrained: "Keep training well! Please continue to do as you do now",
    lowTrained: "Training is poor these days∙∙∙,Did you forget?",
    needEvaluation:
      "You haven't done a recent evaluation. Please proceed with the evaluation!",
  },
  DatePicker: {
    ...$Common,
    ...$PatientRelated,
    notChooseSameDate: "Please select different dates.",
    chooseDates: "Please select dates.",
    showToday: "TODAY",
    ok: "Done",
  },
  TableFilter: {
    ...$Common,
    ...$PatientRelated,
    trainStatus: "Train Status",
    finishedTraining: "On track",
    inTraining: "In progress",
    noTraining: "Not started",
    institutionName: "Institution",
    done: "done",
    notDone: "Not done",
  },

  PatientBasicInfoAddressInput: {
    ...$Common,
    ...$PatientRelated,
    search: "Search",
    detailedAddress: "Detailed address",
  },
  PatientDiagnosticInfo: {
    ...$Common,
    ...$PatientRelated,
    title: "Diagnostic information",
    symptomAndContentNotes: "Symptoms and remarks",
    severityLevel: "Severity level",
  },
  PatientInfo: {
    ...$Common,
    ...$PatientRelated,
    add: "Add",
  },
  CompleteAddPatientDialog: {
    ...$Common,
    ...$PatientRelated,
    title: "[{msg}] has been added.",
    content1: "Please provide the following information to the patient.",
    content2: "You may also find this information under \"Patient Info.\"",
    ok: "Confirm",
  },
  Evaluation: {
    ...$Common,
    ...$PatientRelated,
    editItem: "the evaluation!",
  },
  VoiceEvaluation: {
    ...$Common,
    ...$PatientRelated,
  },
  VoiceEvaluationHeader: {
    ...$Common,
    ...$PatientRelated,
    title: "Speech evaluation",
  },
  ListenVoices: {
    ...$Common,
    ...$PatientRelated,
    title: "Listen",
    readWord: "Word reading",
    readParagraph: "Passage reading",
    a: "a",
    i: "i",
    u: "u",
    p: "p",
    t: "t",
    k: "k",
    ptk: "ptk",
    utap: "",
    readingFallParagraphs: "",
  },
  EvaluationElements: {
    ...$Common,
    ...$PatientRelated,
    title: "Evaluation features",
    editItem: "the evaluation!",
    edit: "Edit",
    readWord: "Word reading",
    readParagraph: "Passage reading",
    rating: "Rating",
    forced_inspiration_expiration_tip:
      "Speech is interrupted by sudden, forced inspiration and expiration sighs.",
    audible_inspiration_tip: "There is audible, breathy inspiration. ",
    inhalatory_stridor_tip:
      "Similar to audible inspiration but characterized by actual rough phonation due to vocal fold approximation and oscillation during inhalation. ",
    grunt_at_end_of_expiration_tip:
      "There is a grunt at the end of expiration. ",
    pitch_level_tip:
      "Pitch of voice sounds consistently too low or too high for age and sex.",
    pitch_break_tip: "Pitch of voice shows sudden and uncontrolled variation.",
    monopitch_tip:
      "Voice is characterized by a monopitch or monotone. Voice lacks normal pitch and inflectional changes. It tends to stay at one pitch level. ",
    voice_tremor_tip: "Voice shows shakiness or tremulousness. ",
    myoclonus_tip:
      "1-4 Hz rhythmic tremorlike 'beats' in the voice, sometimes sufficient to cause brief voice arrests, usually heard only during vowel prolongation.",
    diplophonia_tip: "Simultaneous perception of two different pitches.",
    monoloudness_tip:
      "Voice shows monotony of loudness. It lacks normal variations in loudness. ",
    loudness_decay_tip: "There is progressive diminution or decay of loudness.",
    alternating_loudness_tip: "There are alternating changes in loudness.",
    excessive_loudness_variation_tip:
      "Voice shows sudden, uncontrolled alterations in loudness, sometimes becoming too loud, sometimes too weak.",
    harsh_voice_tip: "Voice is harsh, rough, and raspy.",
    hoarse_wet_voice_tip: "There is wet, \"liquid-sounding\" hoarseness.",
    breathy_voice_tip: "Voice is continuously breathy, weak, and thin.",
    strained_strangled_voice_tip:
      "Voice (phonation) sounds strained or strangled (an apparently effortful squeezing of voice through glottis).",
    voice_stoppages_tip:
      "There are sudden stoppages of voice air stream (as if some obstacle along vocal tract momentarily impedes flow of air). ",
    flutter_tip:
      "Rapid, relatively low-amplitude voice tremor (perceived as in the 7-12 Hz range), usually most apparent during vowel prolongation.",
    rate_tip: "Rate of actual speech is abnormally slow or rapid. ",
    short_phrase_tip:
      "Phrases are short (possibly because inspirations occur more often than normal). Speaker may sound as if he or she has run out of air. Speaker may produce a gasp at the end of a phrase. ",
    increase_of_rate_in_segments_tip:
      "Rate increases progressively within given segments of connected speech. ",
    increase_of_rate_overall_tip:
      "Rate increases progressively from beginning to end of sample. ",
    reduced_stress_tip:
      "Speech shows reduction of proper stress or emphasis patterns. ",
    variable_rate_tip: "Rate alternates from slow to fast. ",
    prolonged_interval_tip:
      "There is prolongation of interword or intersyllable intervals. ",
    inappropriate_break_tip: "There are inappropiiate silent intervals. ",
    short_rushes_of_speech_tip:
      "There are short rushes of speech separated by pauses. ",
    excess_and_equal_stress_tip:
      "There is excess stress on usually unstressed parts of speech (e.g., monosyllabic words, unstressed syllables of polysyllabic words).",
    hypernasality_tip:
      "Is the patient making unusually excessive nasal sounds?",
    hyponasality_tip: "Is the patient making unusually few nasal sounds?",
    nasal_emission_tip: "Does the patient seem to snort when speaking?",
    weak_pressure_consonants_tip: "Are the consonants articulated weakly?",
    imprecise_consonants_tip: "Are the consonants inaccurate?",
    prolonged_phonemes_tip:
      "Does articulation occur by prolonging the phonemes?",
    repeated_phonemes_tip: "Are phonemes repeated?",
    irregular_articulatory_breakdown_tip:
      "Does the quality of articulation decrease irregularly?",
    distorted_vowels_tip: "Are the vowels distorted?",
    none: "0_ Low",
    severe: "100_ High",
  },
  EvaluationResultHeader: {
    ...$Common,
    ...$PatientRelated,
    title: "Speech evaluation and questionnaire result",
    round: "{msg}round",
  },
  SubsystemDetailResult: {
    ...$Common,
    ...$PatientRelated,
    subsystem: "Subsystems",
  },
  SubsystemResultChart: {
    ...$Common,
    ...$PatientRelated,
    ...$TrainingLevelRelated,
    beginning: "Baseline",
    recently: "Latest",
  },
  SubsystemFeatureDetailResult: {
    ...$Common,
    ...$PatientRelated,
    scoreDetailResults: "detailed score result",
    detailedItem: "Feature",
    evaluationResult: "Score",
    scoreChange: "Score change",
    back: "back",
  },
  DetailResultTable: {
    ...$Common,
    ...$PatientRelated,
  },
  MPT: {
    ...$Common,
    ...$PatientRelated,
    normalStandard: "Avg",
  },
  DDK: {
    ...$Common,
    ...$PatientRelated,
    p: "/p/",
    t: "/t/",
    k: "/k/",
    ptk: "/ptk/",
    normalStandard: "Avg",
  },
  PHQ9: {
    ...$Common,
    ...$PatientRelated,
    title: "PHQ9",
    points: "score",
    normal: "1 Low",
    mild: "10",
    moderate: "15",
    severe: "27 High",
  },
  QualityOfLife: {
    ...$Common,
    ...$PatientRelated,
    title: "QoL-dys",
    wordProperty: "SC score",
    situationalDifficulties: "SD score",
    compensationStrategy: "CS score",
    perceptualReaction: "PR score",
  },
  WordArticulation: {
    ...$Common,
    ...$PatientRelated,
    title: "Speech intelligibility",
    none: "0_ Low",
    mild: "25",
    moderate: "50",
    moderateSevere: "75",
    severe: "100_ High",
  },
  DIPBefore: {
    ...$Common,
    ...$PatientRelated,
    title: "DIP",
  },
  DIPTable: {
    ...$Common,
    ...$PatientRelated,
    title: "DIP scores by section",
  },
  NotEvaluate: {
    ...$Common,
    ...$PatientRelated,
    subsystem: "Subsystem",
    MPT: "MPT",
    DDK: "DDK",
    PHQ9: "PHQ9",
    DIP: "DIP",
    qualityOfLife: "QoL-dys",
    wordArticulation: "Speech intelligibility",
    careGiverWordArticulation: "Caregiver’s speech intellibility",
    caregiverTable: "Caregiver's evaluation",
    caregiverWordArticulation: "",
    totalRetentionUsers: "total Retention Users",
    finishedRetentionUsers: "finished Retention Users",
    appUsage: "Engagement rate",
    trainingPCT: "training PCT",
    usageByInstitution: "Usage by Institution",
  },
  TrainingManagement: {
    ...$Common,
    ...$PatientRelated,
    ...$TrainingLevelRelated,
    all: "All subsystems",
    editItem: "the training that you selected!",
    ok: "training",
  },
  TrainingManagementHeader: {
    ...$Common,
    ...$PatientRelated,
    title: "Add and customize exercises",
    trainingArea: "Training subsystems",
    all: "All subsystems",
  },
  TrainingItem: {
    ...$Common,
    ...$PatientRelated,
    ...$TrainingLevelRelated,
    warm_up_trainingManagementDescription: "Posture correction videos",
    oral_motor_exercise_trainingManagementDescription:
      " Oral motor exercise videos",
    sustained_phonation_trainingManagementDescription: "Making long sounds",
    gliding_up_trainingManagementDescription: " Raising the pitch",
    gliding_down_trainingManagementDescription: " Lowering the pitch",
    louder_trainingManagementDescription: "Increasing the volume",
    pitch_up_down_trainingManagementDescription:
      "Alternating high and low pitch",
    effortful_closure_trainingManagementDescription:
      " Effortful closure videos",
    yawn_sigh_trainingManagementDescription: " Yawn-sign exercise videos",
    minimal_pair_trainingManagementDescription:
      "Reading minimally paired words",
    reading_stress_trainingManagementDescription: "Practicing sentence stress",
    deep_breath_trainingManagementDescription:
      "Breathing through nose and mouth",
    tapping_trainingManagementDescription: " Finger tapping while reading",
    reading_stretch_trainingManagementDescription: "Reading sentences slowly",
    reading_loud_trainingManagementDescription: "Reading sentences loudly",
  },
  TrainingManagementWeekTrainingList: {
    ...$Common,
    ...$PatientRelated,
    ...$TrainingLevelRelated,
    title: "This week's training",
    noTrainingList: "Please select at least 1 training exercise.",
  },
  DetailTrainingSetting: {
    ...$Common,
    ...$PatientRelated,
    ...$TrainingLevelRelated,
    warm_up_trainingDescription:
      "This exercise involves watching a posture correction video before starting the training.",
    oral_motor_exercise_trainingDescription:
      "This exercise involves watching videos for oral motor exercises.",
    sustained_phonation_trainingDescription:
      "This exercise involves sustaining sounds for as long as possible.",
    gliding_up_trainingDescription:
      "This exercise involves gradually raising the pitch.",
    gliding_down_trainingDescription:
      "This exercise involves gradually lowering the pitch.",
    louder_trainingDescription:
      "This exercise involves gradually increasing the volume.",
    pitch_up_down_trainingDescription:
      "This exercise involves repetitively raising and lowering the pitch.",
    effortful_closure_trainingDescription:
      "This exercise involves watching videos for strengthening the vocal cords (effortful closure exercises).",
    yawn_sigh_trainingDescription:
      "This exercise involves watching videos for relaxing the vocal cords (yawn-sigh exercises).",
    minimal_pair_trainingDescription:
      "This exercise involves repetitively reading minimally paired words.",
    reading_stress_trainingDescription:
      "This exercise involves reading sentences with emphasis on certain words.",
    deep_breath_trainingDescription:
      "This exercise involves deep breathing through the nose and mouth.",
    tapping_trainingDescription:
      "This exercise involves finger tapping while reading sentences.",
    reading_stretch_trainingDescription:
      "This exercise involves reading sentences slowly.",
    reading_loud_trainingDescription:
      "This exercise involves reading sentences loudly.",
  },
  SetWeekTrainingDialog: {
    ...$Common,
    ...$PatientRelated,
    ...$TrainingLevelRelated,
    title: "Set this week's training",
    description:
      "Please check this week's training list and the three challenges that you have set.",
    ok: "Continue",
  },
  TrainingStatus: {
    ...$Common,
    ...$PatientRelated,
    title: "Calendar",
  },
  TrainingStatusCalendar: {
    ...$Common,
    ...$PatientRelated,
    title: "Calendar",
    resetDate: "Date range",
    sendNotification: "Send notification",
    trialPeriod: "Trial period",
    trainingRate: "Training rate",
    notification: "Notification",
  },
  TrainingStatusChart: {
    ...$Common,
    ...$PatientRelated,
    title: "Training trend",
  },
  TrainingReportWeekTrainingList: {
    ...$Common,
    ...$PatientRelated,
    ...$TrainingLevelRelated,
    displayName: "Exercise list of the week",
    challengeTrainStatus: "Weekly challenge",
    completionRate: "Completion rate",
    listening: "Listen",
    download: "Download",
    noData: "Training has not been set up yet!",
  },
  TrainingResult: {
    ...$Common,
    ...$PatientRelated,
    ...$TrainingLevelRelated,
  },
  UserProfile: {
    ...$Common,
    ...$PatientRelated,
    title: "Profile",
    basicInfo: "Basic information",
    accountInfo: "Account information",

    dialogTitle: "Are you sure you want to delete your account?",
    content:
      "All information will be permanently lost after deleting this account. The information cannot be retrieved.",
    ok: "Delete account",
  },
  UserProfileHeader: {
    ...$Common,
    ...$PatientRelated,
    title: "Profile",
  },
  UserLogout: {
    ...$Common,
    ...$PatientRelated,
    logout: "Logout",
    content: "Are you sure you want to logout?",
  },
  UserLogoutHeader: {
    ...$Common,
    ...$PatientRelated,
    title: "Logout",
  },
  UserList: {
    ...$Common,
    ...$PatientRelated,
  },
  UserListHeader: {
    ...$Common,
    ...$PatientRelated,
    title: "User List",
  },
  UsersTable: {
    ...$Common,
    ...$PatientRelated,

    role: "Role",
    totalPeople: "{total} people in total.",
  },
  UsersTableHeader: {
    ...$Common,
    ...$PatientRelated,
    title: "User information",
    addUser: "Add user",
  },
  UserAdd: {
    ...$Common,
    ...$PatientRelated,
  },
  UserAddHeader: {
    ...$Common,
    ...$PatientRelated,
    title: "User Add",
    excelDownload: "Excel form download",
    excelUpload: "Excel Download",

    role: "Role",
  },
  UserInputTable: {
    ...$Common,
    ...$PatientRelated,

    role: "Role",
    totalPeople: "{total} people in total.",

    invite: "Invite",
  },
  TrainingResultChart: {
    ...$Common,
    ...$PatientRelated,
    ...$TrainingLevelRelated,
  },
  DetailTrainingSettingArea: {
    ...$Common,
    ...$PatientRelated,
    ...$TrainingLevelRelated,
  },
  SetTrainingLevel: {
    ...$PatientRelated,
    ...$TrainingLevelRelated,
    highest: "Master",
    upper: "Advanced",
    middle: "Intermediate",
    low: "Beginner",
    times: "Number of prolonged sounds",
  },
  DefaultSettingArea: {
    ...$Common,
    ...$TrainingLevelRelated,
  },
  MinimalPairSettingArea: {
    ...$Common,
    ...$PatientRelated,
    ...$TrainingLevelRelated,
    setTrainingLevel: "Training customization",
    bilabial_sound: "bilabial sound",
    labiodental: "labiodental",
    alveolar: "alveolar",
    velar: "velar",
    nasalization: "nasalization",
    alveolar_for_palatal_sounds: "alveolar for palatal sounds",
    stopping: "stopping",
    cluster_reduction_l_blen: "Cluster reduction: /l/ in l-blends ",
    cluster_reduction_s_blen: "Cluster reduction: /s/ in s-blends",
  },
  UserTable: {
    ...$Common,
    ...$PatientRelated,
    ...$RoleRelated,
  },
  BodyCellRole: {
    ...$Common,
    ...$PatientRelated,
    ...$RoleRelated,
  },
  CareGiverHeader: {
    ...$Common,
    ...$PatientRelated,
    ...$RoleRelated,
    title: "Caregiver’s evaluation result",
  },
  CaregiverResultTable: {
    ...$Common,
    ...$PatientRelated,
    ...$RoleRelated,
    date: "Date",
    week: "Week",
    topic: "Topic",
    listen: "Listen",
    selectionResults: "Selection",
  },
  CareGiverWordArticulation: {
    ...$Common,
    ...$PatientRelated,
    ...$RoleRelated,
    title: "Caregiver’s speech intelligibility",
    none: "0_ Low",
    mild: "25",
    moderate: "50",
    moderateSevere: "75",
    severe: "100_ High",
  },
  UserDeleteDialog: {
    ...$Common,
    ...$PatientRelated,
    deleteAccount: "Delete account",
    title: "Are you sure you want to delete your account?",
    content1:
      "All information will be permanently lost after deleting this account. ",
    content2: "The information cannot be retrieved.",
  },
  LastNotification: {
    title: "Last Notification",
    totalNum: "total of {msg}",
  },
  SendAlarm: {
    ...$Common,
    title: "Send Alarm",
    subtitle:
      "Select a notification statement to send notifications and texts to the patient.",
    titleLabel: "title",
    contentsLabel: "contents",
    length: "",
    send: "Send",
    basicMessage1Title: "Another week has started! ",
    basicMessage1Content: "Please proceed with today's training!",
    basicMessage2Title: "You didn't forget right?",
    basicMessage2Content: "You've must been busy... Coming today?",
  },
  ConfirmSendDialog: {
    ...$Common,
    title: "Do you really want to send a notification?",
    subtitle: "Send the following to the patient's notification and text.",
    NoContents: "Please register your device.",
    completed: "Transfer completed",
  },
  EvaluationDialog: {
    ...$Common,
    title: "Activate evaluation",
    floatPopup: "Activate",
    completed: "Transfer completed",
    guideMessage:
      "Please press [Activate] button to allow patient to proceed with the evaluation.",
  },
  RetentionInformation: {
    ...$Common,
    appUsage: "App usage",
    trainingRate: "Training rate",
    usedDaysTotal: "Total usage days ",
    usedDaysReal: "Weekday usage days",
    appUsageGuide: "(based on training days)",
    trainingRateGuide: "(based on progress)",
    usedDaysTotalGuide: "(including weekends)",
    usedDaysRealGuide: "(excluding weekends)",
    days: "{msg}",
  },
  TrainingReport: {
    ...$Common,
  },
  RetentionHeader: {
    ...$Common,
    title: "Retention",
  },
  PatientTrainingInformation: {
    ...$Common,
    totalRetentionUsers: "Total number of patients",
    finishedRetentionUsers: "Completed number of patients",
    appUsage: "Engagement rate",
    trainingPCT: "Training rate",
    appUsageGuide: "",
    trainingPCTGuide: "",
  },
  UsageByInstitution: {
    ...$Common,
    title: "Usage by Institution",
  },
  PatientsSelectDialog: {
    ...$Common,
    ...$PatientRelated,
    title: "Select patients",
    organizationInfo: "institution",
    medicalNum: "Voucher no.",
    add: "Add",
    guideMessage: "{msg} selected",
  },
  RetentionPatientsTable: {
    ...$Common,
    ...$PatientRelated,
    organizationInfo: "institution",
    medicalNum: "Voucher no.",
    title: "Patient information",
    avgTrain: "Usage",
    avgTrainField: "{msg} day/week",
    done: "Done",
    planStartTS: "Starting date",
  },
  DefaultEvaluationValues: {
    ...$Common,
    ...$TrainingLevelRelated,
    title: "Baseline (evaluation) vale of {msg}",
    tooltipGuide: "Baseline values from the latest evaluation",
  },
};
