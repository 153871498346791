import { RouteRecordRaw } from "vue-router";
import { LoginRoutesConst } from "src/const/routesConst";

const routes: RouteRecordRaw[] = [
  {
    path: "/",
    component: () => import("layouts/LayoutsIndex.vue"),
    children: [
      {
        path: "/",
        name: LoginRoutesConst.Dashboard,
        component: () =>
          import(
            /* webpackChunkName: "views-Dashboard" */ "pages/Dashboard.vue"
          ),
      },
      {
        path: "/retention",
        name: LoginRoutesConst.Retention,
        component: () =>
          import(
            /* webpackChunkName: "views-Dashboard" */ "pages/Retention.vue"
          ),
        meta: {
          accessRole: [255, 245],
        },
      },
      {
        path: "/patients/:patientUUID",
        name: LoginRoutesConst.Patient,
        component: () =>
          import(
            /* webpackChunkName: "views-Patient" */ "pages/Dashboard/Patient.vue"
          ),
        props: true,
      },
      {
        path: "/user/profile",
        name: LoginRoutesConst.UserProfile,
        component: () =>
          import(
            /* webpackChunkName: "views-UserProfile" */ "pages/user/UserProfile.vue"
          ),
      },
      {
        path: "/user/list",
        name: LoginRoutesConst.UserList,
        component: () =>
          import(
            /* webpackChunkName: "views-UserList" */ "pages/user/UserList.vue"
          ),
      },
      {
        path: "/user/add",
        name: LoginRoutesConst.UserAdd,

        component: () =>
          import(
            /* webpackChunkName: "views-UserAdd" */ "pages/user/UserAdd.vue"
          ),
      },
      {
        path: "/user/logout",
        name: LoginRoutesConst.UserLogout,
        component: () =>
          import(
            /* webpackChunkName: "views-Profile" */ "pages/user/UserLogout.vue"
          ),
      },
    ],
  },

  {
    path: "/temp",
    name: "temp",
    component: () =>
      import(/* webpackChunkName: "views-temp" */ "pages/temp.vue"),
  },

  // Always leave this as last one,
  // but you can also remove it
  {
    path: "/:catchAll(.*)*",
    component: () => import("pages/ErrorNotFound.vue"),
  },
];

export default routes;
