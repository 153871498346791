import {boot} from "quasar/wrappers";
import useHaiiExAuth from "src/composables/server/useHaiiExAuth";

export default boot(async () => {
  const exAuth = useHaiiExAuth();
  const isProduction = process.env.VUE_APP_ENV === "prod";
  const signInRedirectURI = window.location.origin;

  const setting = localStorage.getItem(`stc_${process.env.VUE_APP_ENV}/setting`);
  let lang = JSON.parse(setting || "{}").locale;

  if (!lang) {
    lang = navigator.language === "ko-KR" ? "ko" : "en"
  }


  exAuth.init({
    serviceName: "stc",
    lang: lang,
    isProduction,
    signInRedirectURI,
  });
  await exAuth.manageToken(true);
});
